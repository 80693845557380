.custom-errors {
  color: red;
  padding-top: 1rem;
  display: block;
  position: relative;
  &.token {
    padding-top: 3rem;
  }
}

.contact-form {
  padding-top: 0;
  div,
  p {
    padding-top: 1rem;
  }

  .radio-group {
    div {
      padding-top: 0;
    }

    padding-bottom: 2rem;

    label {
      min-width: 25%;
      padding-top: 0;
      padding-bottom: 0;
    }
    .radio-label {
      position: absolute;
      text-align: justify;
      font-weight: 300;
    }
    .radio-label::before {
      left: 0;
      top: 0.5rem;
    }
    .radio-label::after {
      left: 0.5rem;
      top: 1rem;
    }

    .radio-block {
      height: 4rem;
    }
  }
}

.submit-button {
  width: 100%;
  p {
    color: black;
  }
}
.aux-auth-text {
  color: black;

  a {
    color: black;
  }
}

.help-text {
  opacity: 0.4;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.success-forgot {
  color: green;
  font-size: 2.5rem !important;
  line-height: 2.5rem !important;
  margin-top: 4rem;
}
