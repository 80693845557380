.welcome-page {
  .container {
    max-width: 153rem;
  }

  .banner {
    background-color: #fafafa;
    padding: 4vw 0;
    text-align: center;

    .container {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
    }

    h1 {
      margin-bottom: 2rem;
    }
  }

  .series-wrapper {
    margin-top: 4vw;

    @include flexbox;
    @include justify-content(center);
  }

  .series-item {
    width: calc((100%/3) - 4vw);
    margin: 1vw;
    text-align: center;

    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);

    .img-block {
      background-color: $color-light-bg;
      display: block;
      width: 100%;
      padding: 2rem;

      @include flex(1);
    }

    a {
      width: 100%;
      height: 100%;
      display: block;

      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);

      img {
        transition: transform 0.35s ease-in-out, opacity 0.2s linear;
        transform: scale(1) translateZ(0);
      }

      &:hover,
      &:focus {
        img {
          transition: transform 0.4s ease-out, opacity 0.2s !important;
          transform: scale(1.05) translateZ(0);
        }
      }
    }

    h3 {
      text-transform: uppercase;
      font-size: 2rem;
      margin: 2rem 0;
      padding: 0;
      min-height: 5rem;

      a {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .banner {
      padding: 3rem 0;

      h1 {
        padding-top: 2rem;
        max-width: 90%;
      }
    }

    .series-wrapper {
      margin-top: 3rem;

      @include flex-direction(column);
      @include align-items(center);
    }

    .series-item {
      width: 100%;
      margin: 2rem;
      max-width: 50rem;

      h3 {
        min-height: auto;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .banner {
      padding: 1rem 0;
    }

    .series-wrapper {
      margin-top: 2rem;
    }

    .series-item {
      h3 {
        font-size: 1.8rem;
      }
    }
  }
}

.series-page {
  .sticky-container {
    height: auto;
  }

  .banner {
    background-color: $color-light-bg;
    background-image: url('/images/afa-members/tree.png');
    background-size: 1280px auto;
    background-position: center;
    background-repeat: no-repeat;
    padding: 2vw 0;
    text-align: center;
    overflow: hidden;

    .container {
      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
    }

    img {
      max-width: 30rem;
    }
  }

  h1 {
    padding-top: 0;
    text-transform: uppercase;
    font-size: 4rem;
    margin-bottom: 2rem;
    letter-spacing: 0;
    text-align: center;
  }

  .series-nav {
    background: #fff;
    padding: 3rem 0;
    z-index: 101;

    .container {
      @include align-items(center);
      @include flexbox;
      @include justify-content(center);
    }

    .series-nav-item {
      padding: 0 2rem;
      text-transform: uppercase;
      text-align: center;

      a {
        color: $color-primary;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $color-secondary;
        }

        &.active {
          color: $color-secondary;
        }
      }
    }
  }

  .series-content {
    padding-top: 8rem;
    text-align: left;

    h4 {
      color: $color-primary;
      opacity: 1;
    }

    h5 {
      color: $color-secondary;
      font-weight: bold;
      text-transform: uppercase;
    }

    .download-btn {
      background-color: $color-secondary;
      border: 0;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;
      margin: 1rem 0;
      padding: 1em;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      transition: all 0.3s;
      border-radius: 0.5rem;
      background-image: url('/images/afa-members/download-icon.svg');
      background-size: auto 30%;
      background-repeat: no-repeat;
      background-position: 1.5rem center;
      padding-left: 3.5rem;
      animation: none;

      &:hover,
      &:focus {
        background-color: $color-secondary-dark;
        animation: none;
      }
    }

    .download-links {
      margin: 1rem auto;

      a {
        color: $color-secondary;
      }
    }

    .overview {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 4rem;
      margin-bottom: 2rem;
    }

    .interviews {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 2rem;
      margin-bottom: 2rem;

      .container {
        max-width: 1500px;
      }
    }

    .videos-wrapper {
      @include flexbox;
      @include justify-content(space-between);
      @include flex-wrap(wrap);
    }

    .videos-wrapper-item {
      width: 29%;
      margin: 2%;
      max-width: 47rem;
      text-align: center;

      @include flexbox;
      @include flex-direction(column);

      p {
        margin: 1rem 0;
      }

      img {
        max-width: 100%;
        margin-bottom: 2rem;
      }

      .bottom-align {
        margin-top: auto;
      }
    }

    .aspect-ratio {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      display: block;
      margin-bottom: 1.5rem;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        aspect-ratio: 16 / 9;
        border: 0;
      }
    }

    .books {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    }

    .books-wrapper {
      @include flexbox;
      @include justify-content(center);
      @include flex-wrap(wrap);
    }

    .books-wrapper-item {
      width: 19%;
      margin: 3%;
      text-align: center;
      max-width: 25rem;

      @include flexbox;
      @include flex-direction(column);
      @include align-items(center);

      img {
        max-width: 100%;
        box-shadow: 1rem 1rem 3rem rgba(0, 0, 0, 60%);
        margin-bottom: 2rem;
        height: 18.8rem;
        width: auto;
      }

      .bottom-align {
        margin-top: auto;
      }
    }

    .audios {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 2rem;
      margin-bottom: 2rem;

      .audios-wrapper {
        img {
          width: 100%;
        }
      }

      h5 {
        margin-bottom: 1.5rem;
      }

      .audios-wrapper-item {
        margin-top: 6rem;
      }

      .rhap_container {
        button {

          &:hover,
          &:focus {
            color: inherit;
            background-color: transparent;
            border-radius: 0;
          }
        }
      }
    }

    section:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 820px) {
    h1 {
      margin-top: 4rem;
      font-size: 3.6rem;
    }

    .series-content {
      .videos-wrapper,
      .books-wrapper {
        @include justify-content(center);
      }

      .videos-wrapper-item {
        width: 46%;
      }

      .books-wrapper-item {
        width: 27%;
      }

      .audios-wrapper-item:last-child {
        margin-bottom: 4rem;
      }
    }
  }

  @media screen and (max-width: 580px) {
    h1 {
      margin-top: 4rem;
      font-size: 3.2rem;
    }

    .series-nav .series-nav-item {
      padding: 0 1.5rem;
    }

    .series-content {

      .videos-wrapper-item,
      .books-wrapper-item {
        width: 96%;
        margin-bottom: 4rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 2.8rem;
    }

    .series-nav .series-nav-item {
      font-size: 1.5rem;
    }
  }
}