header {
    .logo {
        width: auto !important;

        img {
            height: 6.5rem;
            width: auto !important;

            @media screen and (max-width: 360px) {
                height: 4.5rem;
                margin-top: 0.75rem;
            }
        }
    }



    .header-wrapper .nav-menu-list {
        margin-right: 2rem;
    }

    button,
    button:hover,
    button:focus {
        padding: 0;
        border: 0;
        background-color: transparent;
        color: $color-primary;
        font-weight: bold;
        border-radius: 0;
        text-transform: uppercase;
        letter-spacing: normal;
        text-decoration: none;
        font-size: 1.6rem;

        &:hover {
            text-decoration: none;
            color: $color-secondary;
        }
    }
}