/* Colors */
@import 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap';

$font-general: 'Karla', helvetica, arial, sans-serif;
$font-secondary: 'Karla', helvetica, arial, sans-serif;

$color-primary: #282c2f;
$color-secondary: #7109aa;
$color-secondary-dark: #420563;
$color-accent: #420563;
$color-accent-light: #7109aa;
$price-color: #d8ad4e;

$btn-color: #7109aa;
$border-radius: 0.5rem;

$color-light: #e7f1f6;
$color-light-bg: #f1e6f6;

@import '@hyex/ui/styles/hyex/globals.module.scss';

@import 'header';
@import 'auth';
@import 'series';

main {
  min-height: calc(100vh - 20rem);
  overflow-x: hidden;
  position: relative;
}

.content {
  margin-top: 8.5rem;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.5rem;
  margin-bottom: 0.25rem;
}

button {
  border-radius: $border-radius;

  &.colored {
    background: $btn-color;

    &:hover,
    &:focus {
      background: $color-accent;
      border-radius: $border-radius;
    }
  }
}

.login-form {
  margin: 0 auto;
  max-width: 50rem;
  width: 100%;
}

.signup-form {
  margin: 0 auto;
  max-width: 50rem;
  width: 100%;

  .split-inputs {
    display: flex;
    gap: 1rem;

    div {
      width: 50%;
    }
  }
  .token {
    div {
      margin: 0 auto;
    }
  }
}

.black {
  color: black;
}
